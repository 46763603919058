import classes from "./questions.module.scss";
// import Input from 'react-phone-number-input/input';
// import { useContext } from 'react';
// import { AppContext } from '../../App';

const Questions = () => {
	// const {focus, setFocus, setFocusName, error, value, setValue} = useContext(AppContext);
    return ( 
        <section style={{paddingBottom:"30px"}} className={classes.question}>
				{/* <div className={classes.halfwrap}> */}
					{/* <div className={classes.halfitem}>
								<h5> Остались вопросы? Просто отправьте заявку!</h5>
							<div className={classes.questionform__form}>
								<form className={classes.form} action="telegram.php" method="post">
									<input 
                                    type="text"
                                    name="name"
                                    className={classes.name}
                                    placeholder="Имя *"
                                    required 
                                    onFocus={()=> {setFocusName(true)}} 
                                    onBlur={()=> setFocusName(false)}   
                                       ></input>
									<Input
										name="phone"  
										className={classes.phone} 
										style={{border: error&focus ? 'red 1px solid' : focus?'green 1px solid': '1px solid #9FACDC'}} 
										required 
										onFocus={()=> {setFocus(true)}} 
										onBlur={()=> setFocus(false)}  
										country="RU"
										maxLength="16"
										minLength="16"
										international
										withCountryCallingCode
										value={value}
										onChange={setValue}
										/>
									<button  className= { error? classes.btn1 + " " + classes.disable :classes.btn1}  type="submit">Отправить</button>
								</form>
							</div>
					</div>	  */}
				{/* </div> */}
		</section>
     );
}
export default Questions;