import classes from './header.module.scss';
import CountdownTimer from '../countdown/Countdown';
import { useContext } from 'react';
import { AppContext } from '../../App'

const Header = () => {
    const {phoneNumber} = useContext(AppContext);
    
    const isMobile = window.innerWidth <= 1050; 
       
    return ( <>
        <header>
          
            <div id='head' className={classes.header__wrapper}>

                 <div className={classes.logo__wrapper}>
                    <p className={classes.logo}>Мастер <span>24/7</span> </p>
                    <div className={classes.line__wrapper}>
                        <div className={classes.logo_my_line_01}></div>
                        <div className={classes.logo_my_icon}></div>
                        <div className={classes.logo_my_line_01}></div>
                    </div>
                 </div>
                 {!isMobile &&  <div className={classes.timer}><CountdownTimer/></div> }  
              <div className={classes.phone__container}>
              {(window.innerWidth > 1200)  ? (<><a className={classes.number} href={`tel: ${phoneNumber}`}> {phoneNumber}</a>
  <p>Круглосуточно</p></>):
(<a className={classes.number__red} href={`tel: ${phoneNumber}`}> {phoneNumber}</a>)}
   {/* <button className={classes.btn} onClick={()=> removeHiddenClass()} >show</button> */}
              </div> 

              
            </div>
     </header>
  
</>
    )
}
 
export default Header;

  