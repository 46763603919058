import classes from './countdown.module.scss';
import { useEffect, useLayoutEffect, useRef, useState } from "react";

 function CountdownTimer() {
  const [startAt, setStartAt] = useState();
  const now = useNow(100, startAt);
  const fromStart = now - (startAt ?? now);
  const countDown = Math.max(0, 180000 - fromStart);
  const [load, setLoad] = useState("")
  useEffect(() => {
    const actionElement = document.getElementById('action');
    if (actionElement) {
        setTimeout(function () { 
        setLoad(true);
    }, 1000);
    }
}, [startAt]); 
//Запуск при загрузке:
  useEffect(() => {
    if (startAt) {
      setStartAt();
    } else {
      setStartAt(Date.now());
    }
}, []);
   
function useNow(updateInterval, enabled, cb) {
  const cbRef = useRef(cb);
  cbRef.current = cb;
  const [now, setNow] = useState(Date.now());

  useLayoutEffect(() => {
    if (!enabled) return;
    setNow(Date.now());
    cbRef.current?.(Date.now());

    const interval = setInterval(() => {
      setNow(Date.now());
      cbRef.current?.(Date.now());
    }, updateInterval);

    return () => {
      clearInterval(interval);
    };
  }, [updateInterval, enabled]);
  return now;
}
let minites = Math.ceil(countDown/60000);
let secunds = Math.ceil(59- (minites*60-countDown/1000))
let text;
if (minites===7 || minites===6 || minites===5 ) {
  text="минут";
}
if (minites===4 || minites===3 || minites===2 ) {
  text="минуты";
}
if (minites===1) {
  text="минута";
}
return ( 
  countDown>0 ? <div id='action' className={load? classes.action +" "+ classes.show :classes.action }>
      <p style={{color:"white"}}>АКЦИЯ!!! Скидка на все работы 15%</p>
      <p>Позвоните и получите скидку</p>
      <p style={{color:"white"}}>До конца акции осталось  <span>{minites} {text} {secunds} сек</span></p> 
  </div> : <div className={classes.action}></div>
 )
}
export default CountdownTimer;