import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './reset.scss'
import './mixins.scss'
import './index.scss'
import './fonts/roboto/stylesheet.css'
import './fonts/oswald/stylesheet.css'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
    <App />

);

