import CountdownTimer from "../countdown/Countdown";
import classes from "./banner.module.scss";
import { useState, useEffect } from "react";
import { useContext } from 'react';
import { AppContext } from '../../App'

const Banner = () => {
  const {phoneNumber, price} = useContext(AppContext);
  const isMobile = window.innerWidth <= 1050; 
  const [height, setHeight] = useState(0); 
useEffect(() => {if (price===false) {
  

  // Определяем высоту шапки при загрузке компонента
  let realheight =document.getElementById('head').offsetHeight
  setHeight(realheight);

  // Подписываемся на событие resize, чтобы обновить высоту при изменении размера окна

  window.addEventListener('resize', () => setHeight(document.getElementById('head').offsetHeight));}

  // Удаляем слушатель события при размонтировании компонента
  return () => window.removeEventListener('resize', () => setHeight(document.getElementById('head').offsetHeight));
}, []);

  return (
  <>
  
    <div className={classes.banner} style={{marginTop: height+'px'}}>
      <div className={classes.banner__wrapper}>
        <h1 style={{marginBottom:"15px"}}>Услуги электрика</h1> 
         {isMobile && <CountdownTimer/>}
        <div className={classes.card__container}>
          <div className={classes.object__obj1}>
            <img
              className={classes.objectImage}
              src={require("./fast-delivery_prev.webp")}
              alt=""
              title="Бесплатный выезд мастера"
            />
            <div className={classes.objectNote}>Бесплатный выезд мастера</div>
          </div>
          <div className={classes.object__obj2}>
            <img
              className={classes.objectImage}
              src={require("./timer_prev.webp")}
              alt=""
              title="Приезд 35 минут"
            />
            <div className={classes.objectNote}>Приезд мастера 1 час, все районы</div>
          </div>
          <div className={classes.object__obj3}>
            <img
              className={classes.objectImage}
              src={require("./edited_prev.webp")}
              alt=""
              title="Ремонт за 1 визит"
            />
            <div className={classes.objectNote}>Качественный ремонт за 1 визит</div>
          </div>
          <div className={classes.object__obj4}>
            <img
              className={classes.objectImage}
              src={require("./sales_prev.webp")}
              alt=""
              title="Ваша скидка 300 руб"
            />
            <div className={classes.objectNote}>
              Работаем Без Посредников. Цены ниже на 25%
            </div>
          </div>
        </div>
          <a className={classes.btntwo} href={`tel: ${phoneNumber}`}> {phoneNumber}</a>
      </div>
    </div>
    </>
  );
};

export default Banner;
