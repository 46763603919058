import { useContext} from 'react';
import Modal from '../modal/Modal';
import classes from './call.module.scss';
import { AppContext } from '../../App'

const Call = ({phoneNumber}) => {
    const {openModal} = useContext(AppContext);
    // const [modalopen, setModalopen]=useState(false)
    // function openModal () {
    //     setModalopen(true);
    //     document.body.style.overflow = 'hidden';
    // }
    return ( 
    <section >
        <div className={classes.callWrapper}>
        <a className={classes.btnCall} href={`tel: ${phoneNumber}`}> позвонить</a>
        {/* <button  onClick={openModal}  className={classes.btnRequest}>Оставить заявку</button> */}
        </div>
        <Modal  ></Modal>
    </section>
     );
}
export default Call;
