import classes from './contacts.module.scss';
import { useContext } from 'react';
import { AppContext } from '../../App'

const Contacts = () => {
    const {phoneNumber} = useContext(AppContext);
    return (
<div id="kontakti" className={classes.contacts__wrapper}>
    <div className={classes.map__container}>
    <img className={classes.img}  src={require("./kartka-moskvy.webp")} alt="Карта" />
    </div>

    <div className={classes.contacts__container}>
        <h2 className={classes.contacts__title}>контакты</h2>
        <div className={classes.contacts__block}>
        <p id="contphone" className={classes.contacts__phone}><strong>Телефон:</strong> {phoneNumber}</p>
        <p className={classes.contacts__mail}> <strong>Почта:</strong> msk89088644627@yandex.ru</p>
        <p className={classes.rezim}><strong>Режим работы:</strong> ежедневно, с 8:00 до 22:00, без обеда и выходных</p>
    </div>
</div>
</div>
        
      );
}
export default Contacts;

