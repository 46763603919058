import classes from "./footer.module.scss";
// import { useContext } from 'react';
// import { AppContext } from '../../App';
const Footer = ({setPrice,scrollToTopQuik }) => {
    // const {scrollToTopQuik, scrollToTopQuik} = useContext(AppContext);
    return ( 
        <>
        <div className= {classes.niz}>
         <button className= {classes.btn12} onClick={()=>{setPrice(true); scrollToTopQuik()}} >Прайс</button>
         </div>
        <p className={classes.ofert} >Сайт не является публичной офертой</p>
        <p className={classes.ofert} style={{marginTop: 0}}>огрнип 322890100015428 ИНН 890700677686</p>
        </>
     );
}
 
export default Footer;