import classes from './product.module.scss'
import { useContext } from 'react';
import { AppContext } from '../../App'
const Button = () => {
    const {phoneNumber} = useContext(AppContext);
    return ( <a className={classes.btn} href={`tel: ${phoneNumber}`}>Получить консультацию</a> );
}
const Product = () => {
    return ( 
        <section style={{paddingTop:'15px'}}>
            <h3 className={classes.title}>Выполняем электромонтажные работы любой сложности с гарантией</h3>
            <p className={classes.sub__title}>Электрики с допусками. Выезд в любой район города. Аварийный и плановый ремонт электрики на дому, в офисе. Работы любой сложности. Замена проводки. Установка розеток, щитков, выключателей, УЗО. Подключаем светильники, люстры. Гарантии на услуги и материалы. Оставьте заявку и узнайте точную стоимость электротехнических работ, мы перезвоним вам через 2 минуты, это вас не к чему не обязывает, ваша скидка до 20%.
</p>
            <div className={classes.card__wrapper}>
            <div className={classes.card}>
                <div className={classes.icon}><img src={require('./img/lamp.webp')} alt="Лампа" /></div>
                <p className={classes.text}>Пропал свет</p>
                <Button/>
                </div>
            <div className={classes.card}>
                <div className={classes.icon}><img src={require('./img/rozetka.webp')} alt="Розетка" /></div>
                <p className={classes.text}>Установка розеток</p>
                <Button/>
                </div>
            <div className={classes.card}>
                <div className={classes.icon}><img src={require('./img/vikluch.webp')} alt="Выключатель" /></div>
                <p className={classes.text}>Установка выключателей</p>
                <Button/>
                </div>
            <div className={classes.card}>
                <div className={classes.icon}><img src={require('./img/box.webp')} alt="Щиток" /></div>
                <p className={classes.text}>Установка щитка</p>
                <Button/>
                </div>
            <div className={classes.card}>
                <div className={classes.icon}><img src={require('./img/provodka.webp')} alt="Электропроводка" /></div>
                <p className={classes.text}>Прокладка электропроводки</p>
                <Button/>
                </div>
            <div className={classes.card}>
                <div className={classes.icon}><img src={require('./img/shetchik.webp')} alt="Счетчик" /></div>
                <p className={classes.text}>Установка электросчетчика</p>
                <Button/>
                </div>
            <div className={classes.card}>
                <div className={classes.icon}><img src={require('./img/shtroba.webp')} alt="Штробление" /></div>
                <p className={classes.text}>Штробление стен</p>
                <Button/>
                </div>
            <div className={classes.card}>
                <div className={classes.icon}><img src={require('./img/avtomat.webp')} alt="Автоматы" /></div>
                <p className={classes.text}>Монтаж автомата</p>
                <Button/>
                </div>
            <div className={classes.card}>
                <div className={classes.icon}><img src={require('./img/uzo.webp')} alt="УЗО" /></div>
                <p className={classes.text}>Монтаж УЗО</p>
                <Button/>
                </div>
            <div className={classes.card}>
                <div className={classes.icon}><img src={require('./img/fire.webp')} alt="Замыкане" /></div>
                <p className={classes.text}>Устранение замыкания</p>
                <Button/>
                </div>
            <div className={classes.card}>
                <div className={classes.icon}><img src={require('./img/svetilnik.webp')} alt="Светильники" /></div>
                <p className={classes.text}>Установка светильников, люстр</p>
                <Button/>
                </div>
            <div className={classes.card}>
                <div className={classes.icon}><img src={require('./img/diagnostika.webp')} alt="Диагностика" /></div>
                <p className={classes.text}>Диагностика электропроводки</p>
                <Button/>
                </div>
            </div>
         </section>
     );
}
export default Product;