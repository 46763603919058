import classes from './service.module.scss'
import { useState } from 'react';
const Service = () => {
    const [state, setState]=useState(false)
    return (
        <section  className={classes.container}>
            <h2 className={classes.title}>Услуги электрика в день обращения недорого, выезд электрика бесплатно, гарантия!</h2>
            <img className={classes.img}  src={require("./master.webp")} alt="Услуги электрика в день обращения" />
            <div className={`${classes.text__wrapper} ${state && classes.open}`}>
                <p>Услуги электрика недорого в  Москве и МО, приезд электрика в день обращения через 1 час после оформления заказа. Если вам нужно вызвать электрика на дом, звоните в компанию «Мастер 24/7». Наш специалист будет у вас в точно оговорённое время. Когда дело касается электричества, без помощи профессионалов обойтись практически невозможно, и неважно – масштабный вы затеяли ремонт либо небольшой. А, может быть, строитесь с нуля?</p>
                <div style={{marginTop:'20px'}}></div>
                 <ul>В нашей компании вы найдете специалистов, которые готовы провести перечень электротехнических работ, необходимых на вашем объекте. Наиболее популярные услуги: 
                    <li> замена и монтаж проводки (открытым или скрытым способом),</li>
                    <li>перенос, замена, монтаж выключателей и розеток,</li>
                    <li>установка электросчётчиков,</li>
                    <li>подключение эёлетрооборудования, бытовой техники,</li>
                    <li>установка аудио- и видео- домофонов,</li>
                    <li>работы с системами электрических тёплых полов,</li>
                    <li>подключение осветительных приборов и так далее.</li>
                 </ul>
                </div>
            <button onClick={()=>setState(!state)} className={classes.btn}> {state?'Скрыть':'Читать дальше' }</button>
        </section>
        
      );
}
export default Service;
// import { CSSTransition } from "react-transition-group";
// import classes from './service.module.scss'
// import { useState } from 'react';
// const Service = () => {
//     const [state, setState]=useState(false)
//     return (<>
//         <section  className={classes.container}>
//                 {/* <a href="#!" className={classes.round__button}>
// 					<span>&#9742;</span>
// 				</a> */}

//             <h2 className={classes.title}>Услуги электрика в день обращения недорого, выезд электрика бесплатно, гарантия!</h2>
//             <img className={classes.img}  src={require("./master.webp")} alt="Услуги электрика в день обращения" />
//             <div className={classes.text__wrapper}>
//                 <p >Услуги электрика недорого в  Москве и МО, приезд электрика в день обращения через 1 час после оформления заказа. Если вам нужно вызвать электрика на дом, звоните в компанию «Мастер Плюс». Наш специалист будет у вас в точно оговорённое время. Когда дело касается электричества, без помощи профессионалов обойтись практически невозможно, и неважно – масштабный вы затеяли ремонт либо небольшой. А, может быть, строитесь с нуля?</p>
//                 <div style={{marginTop:'20px'}}></div>
//                 <CSSTransition  
//                  mountOnEnter	
//                 in={state} 
//                 timeout={2000} 
//                 classNames = {{
//                     enter: classes.card__enter,
//                     enterActive: classes.card__enter__active,
//                     exit: classes.card__exit,
//                     exitActive: classes.card__exit__active}}
//                 unmountOnExit>
//                  <ul>В нашей компании вы найдете специалистов, которые готовы провести перечень электротехнических работ, необходимых на вашем объекте. Наиболее популярные услуги: 
//                     <li> замена и монтаж проводки (открытым или скрытым способом),</li>
//                     <li>перенос, замена, монтаж выключателей и розеток,</li>
//                     <li>установка электросчётчиков,</li>
//                     <li>подключение эёлетрооборудования, бытовой техники,</li>
//                     <li>установка аудио- и видео- домофонов,</li>
//                     <li>работы с системами электрических тёплых полов,</li>
//                     <li>подключение осветительных приборов и так далее.</li>
//                  </ul>
//                  </CSSTransition> 
//                 </div>
//             <button onClick={()=>setState(!state)} className={classes.btn}> {state?'Скрыть':'Читать дальше' }</button>
//         </section>
//         </>
//       );
// }
 
// export default Service;

