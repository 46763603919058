import { useState } from "react";
import classes from "./price.module.scss"
import Footer from "../footer/Footer";
const Price = ({setPrice, scrollToTopQuik}) => {
    const Subtitle = () => {
        return(
            <div className={classes.wrapper}>
                <p className={classes.cen_title}>Наименование работ</p>
                <p className={classes.cen_title} >Ед.изм</p>
                <p className={classes.cen_title} style={{whiteSpace:'nowrap'}}>Цена, руб</p>
            </div>
        )
    }
    const styling = {textAlign:'center', fontWeight:'bold', textTransform:'uppercase'};

function renderItem(item) {
    return (
      <div key={item.id} className={classes.wrapper}>
        <p className={classes.cen_item + " " + classes.type}>{item.type}</p>
        <p className={classes.cen_item}>{item.units}</p>
        <p className={classes.cen_item} style={{ whiteSpace:'nowrap'}}>{item.cost}</p>
      </div>
    );
  }

    const [switchs] = useState([
        {
            id: 1,
            type:'Установка выключателя (бетон)',
            units: 'шт',
            cost: 'от 280'
        },
        {
            id: 2,
            type:'Установка выключателя (кирпич)',
            units: 'шт',
            cost: 'от 270'
        }
        ,
        {
            id: 3,
            type:'Установка выключателя (гипрок)',
            units: 'шт',
            cost: 'от 250'
        }
        ,
        {
            id: 4,
            type:'Установка выключателя (дерево)',
            units: 'шт',
            cost: 'от 230'
        }
        ,
        {
            id: 5,
            type:'Замена выключателя (бетон)',
            units: 'шт',
            cost: 'от 250'
        }
        ,
        {
            id: 6,
            type:'Замена выключателя (кирпич)',
            units: 'шт',
            cost: 'от 240'
        }
        ,
        {
            id: 7,
            type:'Замена выключателя (дерево)',
            units: 'шт',
            cost: 'от 200'
        }
        ,
        {
            id: 8,
            type:'Ремонт выключателя (бетон)',
            units: 'шт',
            cost: 'от 230'
        }
        ,
        {
            id: 9,
            type:'Ремонт выключателя (кирпич)',
            units: 'шт',
            cost: 'от 220'
        }
        ,
        {
            id: 11,
            type:'Ремонт выключателя (гипрок)',
            units: 'шт',
            cost: 'от 200'
        }
        ,
        {
            id: 12,
            type:'Ремонт выключателя (дерево)',
            units: 'шт',
            cost: 'от 190'
        }
    ])

    const [outlet] = useState([
        {
            id: 1,
            type:'Установка розетки (бетон)',
            units: 'шт',
            cost: 'от 280'
        },
        {
            id: 2,
            type:'Установка розетки (кирпич)',
            units: 'шт',
            cost: 'от 270'
        }
        ,
        {
            id: 3,
            type:'Установка розетки (гипрок)',
            units: 'шт',
            cost: 'от 250'
        }
        ,
        {
            id: 4,
            type:'Установка розетки (дерево)',
            units: 'шт',
            cost: 'от 230'
        }
        ,
        {
            id: 5,
            type:'Замена розетки (бетон)',
            units: 'шт',
            cost: 'от 250'
        }
        ,
        {
            id: 6,
            type:'Замена розетки (кирпич)',
            units: 'шт',
            cost: 'от 240'
        }
        ,
        {
            id: 7,
            type:'Замена розетки (гипрок)',
            units: 'шт',
            cost: 'от 220'
        }
        ,
        {
            id: 8,
            type:'Замена розетки (дерево)',
            units: 'шт',
            cost: 'от 200'
        }
        ,
        {
            id: 9,
            type:'Ремонт розетки (бетон)',
            units: 'шт',
            cost: 'от 230'
        }
        ,
        {
            id: 10,
            type:'Ремонт розетки (кирпич)',
            units: 'шт',
            cost: 'от 220'
        }
        ,
        {
            id: 11,
            type:'Ремонт розетки (гипрок)',
            units: 'шт',
            cost: 'от 200'
        }
        ,
        {
            id: 12,
            type:'Ремонт розетки (дерево)',
            units: 'шт',
            cost: 'от 190'
        }
      
        
    ])
    const [light] = useState([
        {
            id: 1,
            type:'Установка настенных светильников, бра (на бетонную стену)',
            units: 'шт',
            cost: 'от 320'
        },
        {
            id: 2,
            type:'Установка настенных светильников, бра (на кирпичную стену)',
            units: 'шт',
            cost: 'от 310'
        }
        ,
        {
            id: 3,
            type:'Установка настенных светильников, бра (на гипрочную стену)',
            units: 'шт',
            cost: 'от 290'
        }
        ,
        {
            id: 4,
            type:'Установка настенных светильников, бра (на деревянную стену)',
            units: 'шт',
            cost: 'от 280'
        }
        ,
        {
            id: 5,
            type:'Замена настенных светильников, бра (на бетонную стену)',
            units: 'шт',
            cost: 'от 290'
        }
        ,
        {
            id: 6,
            type:'Замена настенных светильников, бра (на кирпичную стену)',
            units: 'шт',
            cost: 'от 280'
        }
        ,
        {
            id: 7,
            type:'Замена настенных светильников, бра (на гипрочную стену)',
            units: 'шт',
            cost: 'от 260'
        }
        ,
        {
            id: 8,
            type:'Замена настенных светильников, бра (на деревянную стену)',
            units: 'шт',
            cost: 'от 240'
        }
        ,
        {
            id: 9,
            type:'Ремонт настенных светильников, бра',
            units: 'шт',
            cost: 'от 270'
        }
        ,
        
        {
            id: 10,
            type:'Установка подвесного светильника с монтажом крюка',
            units: 'шт',
            cost: 'от 360'
        }
        ,
        {
            id: 11,
            type:'Установка подвесного светильника на готовый крюк',
            units: 'шт',
            cost: 'от 340'
        }
        
    ])
    const [automats] = useState([
        {
            id: 1,
            type:'Установка автомата 1-полюсного от 6 до 40 А на DIN-рейку',
            units: 'шт',
            cost: 'от 180'
        },
        {
            id: 2,
            type:'Установка автомата 1-полюсного от 6 до 40 А на монтажную панель',
            units: 'шт',
            cost: 'от 210'
        }
        ,
        {
            id: 3,
            type:'Установка автомата 1-полюсного от 50 до 80 А на DIN-рейку',
            units: 'шт',
            cost: 'от 220'
        }
        ,
        {
            id: 4,
            type:'Установка автомата 1-полюсного от 50 до 80 А на монтажную панель',
            units: 'шт',
            cost: 'от 250'
        }
        ,
        {
            id: 5,
            type:'Установка автомата 2-полюсного от 6 до 40 А на DIN-рейку',
            units: 'шт',
            cost: 'от 240'
        }
        ,
        {
            id: 6,
            type:'Установка автомата 2-полюсного от 6 до 40 А на монтажную панель',
            units: 'шт',
            cost: 'от 300'
        }
        ,
        {
            id: 7,
            type:'Установка автомата 2-полюсного от 50 до 80 А на DIN-рейку',
            units: 'шт',
            cost: 'от 270'
        }
        ,
        {
            id: 8,
            type:'Установка автомата 2-полюсного от 50 до 80 А на монтажную панель',
            units: 'шт',
            cost: 'от 330'
        }
        ,
        {
            id: 9,
            type:'Установка автомата 3, 4-полюсного от 6 до 40 А на DIN-рейку',
            units: 'шт',
            cost: 'от 320'
        }
        ,
        {
            id: 10,
            type:'Установка автомата 3, 4-полюсного от 50 до 80 А на DIN-рейку',
            units: 'шт',
            cost: 'от 370'
        }
        ,
        {
            id: 11,
            type:'Установка автомата 3, 4-полюсного от 50 до 80 А на монтажную панель',
            units: 'шт',
            cost: 'от 620'
        }
    ])
    const [cabel] = useState([
        {
            id: 1,
            type:'Прокладка провода, кабеля накладным открытым способом',
            units: 'м.п',
            cost: 'от 55'
        },
        {
            id: 2,
            type:'Укладка провода, кабеля в кабель-канал',
            units: 'м.п',
            cost: 'от 10'
        }
        ,
        {
            id: 3,
            type:'Укладка кабеля, провода в лоток',
            units: 'м.п',
            cost: 'от 10'
        }
        ,
        {
            id: 4,
            type:'Монтаж провода, кабеля в штробе',
            units: 'м.п',
            cost: 'от 50'
        }
        ,
        {
            id: 5,
            type:'Прокладка провода, кабеля в гофре, металлорукаве',
            units: 'м.п',
            cost: 'от 55'
        }
        ,
        {
            id: 6,
            type:'Затяжка кабеля в трубу',
            units: 'м.п',
            cost: 'от 180'
        }
        ,
        {
            id: 7,
            type:'Монтаж кабеля в гладкой ПВХ, ПНД трубе',
            units: 'м.п',
            cost: 'от 35'
        }
        ,
        {
            id: 8,
            type:'Прокладка кабеля по воздуху на тросе',
            units: 'м.п',
            cost: 'от 30'
        }
        ,
        {
            id: 9,
            type:'Монтаж СИП кабеля',
            units: 'м.п',
            cost: 'от 15'
        }
        ,
        {
            id: 10,
            type:'Установка кабель-канала',
            units: 'м.п',
            cost: 'от 70'
        }
        ,
        {
            id: 11,
            type:'Монтаж лотка',
            units: 'шт',
            cost: 'от 255'
        }
        ,
        {
            id: 12,
            type:'Устройство штроб',
            units: 'м.п',
            cost: 'от 65'
        }
        ,
        {
            id: 13,
            type:'Заделывание штроб',
            units: 'м.п',
            cost: 'от 25'
        }
    ])
    const [uzo] = useState([
        {
            id: 1,
            type:'Установка УЗО 2 модуля до 50 А',
            units: 'шт',
            cost: 'от 290'
        },
        {
            id: 2,
            type:'Установка УЗО 2 модуля 63 - 100 А',
            units: 'шт',
            cost: 'от 700'
        }
        ,
        {
            id: 3,
            type:'Установка УЗО 4 модуля до 50 А',
            units: 'шт',
            cost: 'от 440'
        }
        ,
        {
            id: 4,
            type:'Установка УЗО 4 модуля 63 - 100 А',
            units: 'шт',
            cost: 'от 460'
        }
    
    ])

    return (  <>
        <button className={classes.close_btn} onClick={()=>{setPrice(false);scrollToTopQuik()} } type= "button" > <div className={classes.close}>Закрыть <div className={classes.cross}>×</div> </div> </button>
       
        <main>
            <div className={classes.titlewrapper}>
            <h2 className={classes.title}>Прайс-лист на <span className={classes.accent}>услуги электриков</span></h2>
            <p className={classes.subtitletitle}>Предоставляем более 900 услуг</p>
        </div>
            <h4 style={styling}>Выключатели</h4>
            <Subtitle/>
            {switchs.map(renderItem)}
                 
            <h4 style={styling}>Розетки</h4>
            <Subtitle/>
            {outlet.map(renderItem)}
                
            <h4 style={styling}>Освещение</h4>
            {light.map(renderItem)}
               
            <h4 style={styling}>Автоматы</h4>
            <Subtitle/>
            {automats.map(renderItem)}

            <h4 style={styling}>Прокладка кабеля</h4>
            <Subtitle/>
            {cabel.map(renderItem)}

            <h4 style={styling}>Установка УЗО</h4>
            <Subtitle/>
            {uzo.map(renderItem)}   
            <Footer/>
        </main>
        </>
    );
}
 
export default Price;